import useInterval from "@use-it/interval";
import { Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import CountdownTimer from "../CountdownTimer";
import Share from "./../share/Share";
import moment from "moment";

const { Text } = Typography;

const StyledCountdownTimer = styled.div`
  margin-bottom: 24px;
  display: flex;
  position: relative;
`;

const Line = styled.div`
  border-right: 1px solid #d9d9d9;
  margin: 0px 7px 0px 5px;
`;

const StyledSharePosition = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const CountdownTimeBlock = ({ discountTo }) => {
  const [seconds, setSeconds] = useState(0);
  useInterval(() => setSeconds(moment(discountTo).diff(moment()) / 1000), 1000);
  return (
    discountTo && (
      <StyledCountdownTimer>
        <span className="mr-1">優惠倒數</span>
        <Text strong>
          {seconds > 86400 && `${(seconds / 86400).toFixed(0)} 天 `}
          {seconds > 3600 && `${((seconds % 84600) / 3600).toFixed(0)} 時 `}
          {seconds > 60 && `${((seconds % 3600) / 60).toFixed(0)} 分 `}
          {seconds && `${(seconds % 60).toFixed(0)} 秒`}
          {/* <CountdownTimer isDate due={discountTo.toString()} /> */}
        </Text>
        <StyledSharePosition>
          <Share />
        </StyledSharePosition>
      </StyledCountdownTimer>
    )
  );
};

export default CountdownTimeBlock;

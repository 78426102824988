import { Typography, Collapse, Icon } from "antd";
import React from "react";
import styled from "styled-components";

const { Title } = Typography;

const StyledTitleDiv = styled.div`
  font-size: 20px;
  border-left: 6px solid #ff812c;
  padding: 6px 12px 6px 12px;
  margin-bottom: 32px;
`;

const StyledDiv = styled.div`
  color: #000;
  margin: 32px 0px;
`;

const StyledTextP = styled.p`
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
`;

const StyledSection = styled.div`
  margin-bottom: 56px;
`;

const ChapterItem = styled.div`
  padding-left: 24px;
  line-height: 2.5;
`;

const StyledTitle = ({ title, subtitle }) => {
  return (
    <StyledTitleDiv>
      <Title level={4} style={{ marginBottom: 0 }}>
        {title}
      </Title>
      <div style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>
        {subtitle}
      </div>
    </StyledTitleDiv>
  );
};

const StyledText = ({ children }) => {
  return (
    <StyledDiv>
      <StyledTextP>{children}</StyledTextP>
    </StyledDiv>
  );
};

const customPanelStyle = {
  background: "#fff8f4",
  borderRadius: 4,
  marginBottom: 16,
  border: 0,
  overflow: "hidden"
};

const ProjectChapter = () => {
  return (
    <>
      <StyledSection>
        <StyledTitle
          title="經典股票課程 - 科學化計量選股術"
          subtitle="超過 600 分鐘"
        />
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel style={customPanelStyle} header="一、價值投資的起點">
            <div>
              <ChapterItem>1-1 能不投資嗎?可以投資嗎?</ChapterItem>
              <ChapterItem>
                1-2 看大盤：大風大浪-2000年以來的台灣股市
              </ChapterItem>
              <ChapterItem>1-3 看個股-興衰難料</ChapterItem>
              <ChapterItem>1-4 股票投資策略的分類</ChapterItem>
              <ChapterItem>1-5 股票投資策略的效果</ChapterItem>
              <ChapterItem>
                1-6 擇時：為何技術分析如此誘人又相對危險？
              </ChapterItem>
              <ChapterItem>
                1-7 選股：為何基本分析如此惱人又相對可靠
              </ChapterItem>
              <ChapterItem>1-8 市場價格不會有錯，有錯一定是估錯？</ChapterItem>
              <ChapterItem>1-9 投資與投機-並非簡單二分法</ChapterItem>
              <ChapterItem>1-10 價值投資的起點-價值估計</ChapterItem>
              <ChapterItem>1-11 價值投資的起點-股東有何權益</ChapterItem>
              <ChapterItem>1-12 股票的淨值、盈餘和股價的三角關係</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="二、報表的分析">
            <div>
              <ChapterItem>2-1 前言</ChapterItem>
              <ChapterItem>2-2 會計假設</ChapterItem>
              <ChapterItem>2-3 會計原則</ChapterItem>
              <ChapterItem>2-4 會計之假設與原則</ChapterItem>
              <ChapterItem>2-5 會計之原理：會計恆等式</ChapterItem>
              <ChapterItem>2-6 會計原理：借貸法則</ChapterItem>
              <ChapterItem>2-7 會計之程序簡介</ChapterItem>
              <ChapterItem>2-8 財務報表</ChapterItem>
              <ChapterItem>2-9 損益表</ChapterItem>
              <ChapterItem>2-10 資產負債表</ChapterItem>
              <ChapterItem>2-11 現金流量表</ChapterItem>
              <ChapterItem>2-12 財務報表分析之原理</ChapterItem>
              <ChapterItem>2-13 共同比分析</ChapterItem>
              <ChapterItem>2-14 趨勢分析</ChapterItem>
              <ChapterItem>2-15 比率分析（一）償債能力分析</ChapterItem>
              <ChapterItem>2-16 比率分析（二）安定能力分析</ChapterItem>
              <ChapterItem>2-17 比率分析（三）經營能力分析</ChapterItem>
              <ChapterItem>2-18 比率分析（四）獲利能力分析</ChapterItem>
              <ChapterItem>2-19 比率分析（五）市場評價分析</ChapterItem>
              <ChapterItem>2-20 比率分析與同儕分析</ChapterItem>
              <ChapterItem>2-21 比率分析之優缺點</ChapterItem>
              <ChapterItem>2-22 會計操作的原因與影響</ChapterItem>
              <ChapterItem>2-23 會計操作的方法與偵測</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="三、價值估計的方法">
            <div>
              <ChapterItem>3-1 價值估計的流程 </ChapterItem>
              <ChapterItem>3-2 價值估計方法的分類 </ChapterItem>
              <ChapterItem>3-3 貨幣的時間價值 </ChapterItem>
              <ChapterItem>3-4 年金現金流的評價公式 </ChapterItem>
              <ChapterItem>3-5 資金成本 </ChapterItem>
              <ChapterItem>3-6 企業獲利能力的定量與定性分析 </ChapterItem>
              <ChapterItem>
                3-7 股東權益報酬率之定量解析（一）：杜邦分析{" "}
              </ChapterItem>
              <ChapterItem>
                3-8 股東權益報酬率之定量解析（二）：與公司的成長性之關聯{" "}
              </ChapterItem>
              <ChapterItem>
                3-9 股東權益報酬率之定量解析（三）：與股票投資報酬率之關聯{" "}
              </ChapterItem>
              <ChapterItem>
                3-10 股東權益報酬率之定性解析（一）：ＳＷＯＴ分析{" "}
              </ChapterItem>
              <ChapterItem>
                3-11 股東權益報酬率之定性解析（二）：五力分析{" "}
              </ChapterItem>
              <ChapterItem>
                3-12 股東權益報酬率之定性解析（三）：成長佔有矩陣{" "}
              </ChapterItem>
              <ChapterItem>
                3-13 股東權益報酬率之定性解析（四）：定位策略{" "}
              </ChapterItem>
              <ChapterItem>
                3-14 股東權益報酬率之定性解析（五）：競爭策略{" "}
              </ChapterItem>
              <ChapterItem>
                3-15 股東權益報酬率之定性解析（六）：防禦策略{" "}
              </ChapterItem>
              <ChapterItem>3-16 現金流量折現法之原理 </ChapterItem>
              <ChapterItem>3-17 方法一：自由現金權益法 </ChapterItem>
              <ChapterItem>3-18 方法二：自由現金實體法 </ChapterItem>
              <ChapterItem>3-19 方法三：剩餘盈餘權益法 </ChapterItem>
              <ChapterItem>3-20 方法四：剩餘盈餘實體法 </ChapterItem>
              <ChapterItem>3-21 永續成長率的估計 </ChapterItem>
              <ChapterItem>3-22 現金流量折現法之程序 </ChapterItem>
              <ChapterItem>3-23 現金流量折現法之實例 </ChapterItem>
              <ChapterItem>3-24 結論：永續成長率的估計是關鍵 </ChapterItem>
              <ChapterItem>3-25 淨資產清算價值法之原理 </ChapterItem>
              <ChapterItem>3-26 淨資產清算價值法之程序 </ChapterItem>
              <ChapterItem>3-27 淨資產清算價值法之實例 </ChapterItem>
              <ChapterItem>3-28 結論：評估資產的標準是關鍵 </ChapterItem>
              <ChapterItem>3-29 市場比值法之原理 </ChapterItem>
              <ChapterItem>3-30 市場比值法之流程 </ChapterItem>
              <ChapterItem>3-31 市場比值法之實例 </ChapterItem>
              <ChapterItem>3-32 市場比值法之實證（一）：統計法 </ChapterItem>
              <ChapterItem>
                3-33 市場比值法之實證（二）：迴歸分析法{" "}
              </ChapterItem>
              <ChapterItem>3-34 基於理論解析之市場比值法 </ChapterItem>
              <ChapterItem>3-35 結論：公允市場比值的估計是關鍵 </ChapterItem>
              <ChapterItem>3-36 均值回歸 </ChapterItem>
              <ChapterItem>3-37 成長價值法之原理 </ChapterItem>
              <ChapterItem>3-38 成長價值法的參數之估計 </ChapterItem>
              <ChapterItem>3-39 成長價值法之程序 </ChapterItem>
              <ChapterItem>3-40 成長價值法之實例 </ChapterItem>
              <ChapterItem>3-41 成長價值法之實證 </ChapterItem>
              <ChapterItem>3-42 股東權益報酬率與GVI之關聯 </ChapterItem>
              <ChapterItem>3-43 結論：持續係數的估計是關鍵 </ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="四、價值投資的方法">
            <div>
              <ChapterItem>4-1 價值估計方法的比較</ChapterItem>
              <ChapterItem>4-1 價值投資到底能否擊敗市場</ChapterItem>
              <ChapterItem>4-2 觀點一：效率市場假說與風險補償理論</ChapterItem>
              <ChapterItem>4-3 觀點二：行為財務學派</ChapterItem>
              <ChapterItem>4-4 過度/不足反應與價值股/成長股關聯</ChapterItem>
              <ChapterItem>4-5 價值投資原理：價值股與成長股兩構面</ChapterItem>
              <ChapterItem>4-6 價值投資的舉例</ChapterItem>
              <ChapterItem>
                4-7 結論：價格貴不貴要看品質好不好-價值投資的選股
              </ChapterItem>
              <ChapterItem>
                4-8 觀察股票價值面與成長面：從一維到二維
              </ChapterItem>
              <ChapterItem>
                4-9 觀察股票價值面與成長面：從靜態到動態
              </ChapterItem>
              <ChapterItem>
                4-10 觀察股票價值面與成長面的動態定位儀：ROE-P/B動態圖
              </ChapterItem>
              <ChapterItem>4-11 ROE-P/B動態圖：台灣股市的實證</ChapterItem>
              <ChapterItem>4-12 原始值之二季動態過程之視覺化</ChapterItem>
              <ChapterItem>4-13 排序值之二季動態過程之視覺化</ChapterItem>
              <ChapterItem>4-14 排序值之四季動態過程之視覺化</ChapterItem>
              <ChapterItem>
                4-15 結論：市場價格通常對但偶爾會出錯-價值投資的機會
              </ChapterItem>
              <ChapterItem>4-16 選股因子的分類</ChapterItem>
              <ChapterItem>4-17 價值選股因子之原理與方法</ChapterItem>
              <ChapterItem>4-18 成長選股因子之原理與方法</ChapterItem>
              <ChapterItem>4-19 其他選股因子之原理與方法</ChapterItem>
              <ChapterItem>4-20 選股因子的實例</ChapterItem>
              <ChapterItem>4-21 選股因子的實證</ChapterItem>
              <ChapterItem>4-22 結論：投籃進框的方法不只一種</ChapterItem>
              <ChapterItem>4-23條件篩選法之實例</ChapterItem>
              <ChapterItem>4-24條件篩選法之實證(一) 雙因子</ChapterItem>
              <ChapterItem>4-25條件篩選法之實證(二) 三因子</ChapterItem>
              <ChapterItem>4-26結論：物超所值的東西一定不多</ChapterItem>
              <ChapterItem>4-27加權評分法之原理</ChapterItem>
              <ChapterItem>4-28加權評分法之方法：大師怎麼選股?</ChapterItem>
              <ChapterItem>4-29加權評分法之實例</ChapterItem>
              <ChapterItem>4-30加權評分法之實證</ChapterItem>
              <ChapterItem>4-31結論：好方法也要有耐心</ChapterItem>
              <ChapterItem>4-32 成長價值法原理</ChapterItem>
              <ChapterItem>4-33 成長價值法方法</ChapterItem>
              <ChapterItem>4-34 成長價值法實例</ChapterItem>
              <ChapterItem>4-35 成長價值法實證(一) 十等分法實證</ChapterItem>
              <ChapterItem>
                4-36 成長價值法實證(二) 三維視覺化與迴歸建模
              </ChapterItem>
              <ChapterItem>4-37 結論：結合三個投資觀點的選股指標</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="五、大師如何選股">
            <div>
              <ChapterItem>5-1條件篩選法之原理</ChapterItem>
              <ChapterItem>5-2條件篩選法之方法：大師怎麼選股?</ChapterItem>
              <ChapterItem>5-3價值投資之父：班傑明·葛拉漢</ChapterItem>
              <ChapterItem>5-4人間股神：華倫·巴菲特</ChapterItem>
              <ChapterItem>5-5一代大師：彼得·林區</ChapterItem>
              <ChapterItem>5-6股市兵聖：吉姆·史萊特</ChapterItem>
              <ChapterItem>5-7綜合格鬥家：威廉·歐奈爾</ChapterItem>
              <ChapterItem>5-8成長投資之父：菲利普·費雪</ChapterItem>
              <ChapterItem>5-9 價值投資的原理之比較</ChapterItem>
              <ChapterItem>5-10 價值投資的方法之比較</ChapterItem>
              <ChapterItem>5-11 價值投資的實例之比較</ChapterItem>
              <ChapterItem>5-12 價值投資的實證之比較</ChapterItem>
              <ChapterItem>
                5-13 結論：從葛拉漢(價值型投資)到費雪(成長型投資)再到巴菲特
              </ChapterItem>
            </div>
          </Collapse.Panel>
        </Collapse>
      </StyledSection>

      <StyledSection>
        <StyledTitle
          title="如何選擇股票 - 用Excel打造高勝率選股與回測系統"
          subtitle="超過 400 分鐘"
        />
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel
            style={customPanelStyle}
            header="一、Excel 軟體入門與進階"
          >
            <div>
              <ChapterItem>1-1 簡介</ChapterItem>
              <ChapterItem>1-2 Excel 資料</ChapterItem>
              <ChapterItem>1-3 Excel 公式</ChapterItem>
              <ChapterItem>1-4 Excel 函數</ChapterItem>
              <ChapterItem>1-5 Excel 繪圖</ChapterItem>
              <ChapterItem>1-6 Excel 資料排序</ChapterItem>
              <ChapterItem>1-7 Excel 資料篩選</ChapterItem>
              <ChapterItem>1-8 Excel 資料載入</ChapterItem>
              <ChapterItem>1-9 簡介</ChapterItem>
              <ChapterItem>1-10 VBA/Excel互動介面</ChapterItem>
              <ChapterItem>1-11 巨集錄製與修改</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            style={customPanelStyle}
            header="二、股票資料下載與整合"
          >
            <div>
              <ChapterItem>2-1 前言</ChapterItem>
              <ChapterItem>2-2 每季綜合損益表</ChapterItem>
              <ChapterItem>2-3 每季資產負債表</ChapterItem>
              <ChapterItem>2-4 每日收盤行情(股價)</ChapterItem>
              <ChapterItem>2-5 每日本益比、殖利率及股價淨值比</ChapterItem>
              <ChapterItem>2-6 多表格的資料之整合</ChapterItem>
              <ChapterItem>2-7 結語</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="三、股票選擇法">
            <div>
              <ChapterItem>3-1前言</ChapterItem>
              <ChapterItem>3-2選股方法1：條件篩選法</ChapterItem>
              <ChapterItem>3-3選股方法2：評分篩選法</ChapterItem>
              <ChapterItem>3-4選股方法3：評分排序法</ChapterItem>
              <ChapterItem>3-5選股方法4：成長價值法</ChapterItem>
              <ChapterItem>3-6結語</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="四、回測系統的設計">
            <div>
              <ChapterItem>4-1 前言</ChapterItem>
              <ChapterItem>4-2 選股系統使用的選股因子</ChapterItem>
              <ChapterItem>4-3 評分篩選系統之設計：二維切塊法</ChapterItem>
              <ChapterItem>4-4 評分篩選系統之設計：三維切塊法</ChapterItem>
              <ChapterItem>4-5 評分排序系統之設計：單因子排序法</ChapterItem>
              <ChapterItem>4-6 評分排序系統之設計：多因子排序法</ChapterItem>
              <ChapterItem>4-7 評分排序系統之設計：單因子排序法</ChapterItem>
              <ChapterItem>4-8 結語</ChapterItem>
            </div>
          </Collapse.Panel>
        </Collapse>
      </StyledSection>

      <StyledSection>
        <StyledTitle
          title="買賣時機點的選擇 - 用Excel實作的技術分析與回測系統"
          subtitle="超過 400 分鐘"
        />
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel
            style={customPanelStyle}
            header="一、選股與擇時的投資策略"
          >
            <div>
              <ChapterItem>1-1 股票市場的長期趨勢</ChapterItem>
              <ChapterItem>1-2 指數股票型基金(ETF)</ChapterItem>
              <ChapterItem>1-3 股票的投資策略的分類</ChapterItem>
              <ChapterItem>1-4 三個課程與投資策略關係</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel style={customPanelStyle} header="二、技術分析的原理">
            <div>
              <ChapterItem>2-1 前言</ChapterItem>
              <ChapterItem>2-2 技術分析的原理：價量的振盪與趨勢 </ChapterItem>
              <ChapterItem>2-3 順勢系統經典方法：移動平均線</ChapterItem>
              <ChapterItem>2-4 順勢系統經典方法：MACD</ChapterItem>
              <ChapterItem>2-5 擺盪系統經典方法：布林格帶</ChapterItem>
              <ChapterItem>2-6 擺盪系統經典方法：RSI</ChapterItem>
              <ChapterItem>2-7 技術分析的實證總結</ChapterItem>
              <ChapterItem>2-8 Excel的應用</ChapterItem>
              <ChapterItem>2-9 結語</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            style={customPanelStyle}
            header="三、股票資料的下載與整合"
          >
            <div>
              <ChapterItem>3-1 價量資料的下載</ChapterItem>
              <ChapterItem>3-2 技術分析使用的價量指標</ChapterItem>
              <ChapterItem>3-3 單指標排序法</ChapterItem>
              <ChapterItem>3-4 價量指標切塊法</ChapterItem>
              <ChapterItem>3-5 結論</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            style={customPanelStyle}
            header="四、Excel 實作回測(1992-2007)"
          >
            <div>
              <ChapterItem>4-1 前言</ChapterItem>
              <ChapterItem>4-2 價格移動平均法 (Part 1) 原理</ChapterItem>
              <ChapterItem>4-2 價格移動平均法 (Part 2) 散布圖</ChapterItem>
              <ChapterItem>4-2 價格移動平均法 (Part 3) 演算法</ChapterItem>
              <ChapterItem>4-2 價格移動平均法 (Part 4) 結果</ChapterItem>
              <ChapterItem>4-3 成交量移動平均法</ChapterItem>
              <ChapterItem>4-4 價量移動平均法 (條件門檻法)</ChapterItem>
              <ChapterItem>4-5 價量移動平均法 (條件門檻法─多期間)</ChapterItem>
              <ChapterItem>
                4-6 價量移動平均法 (條件門檻法─多期間─移動波動)
              </ChapterItem>
              <ChapterItem>
                4-7 價量移動平均法 (加權評分法─移動波動)
              </ChapterItem>
              <ChapterItem>4-8 布林格帶法</ChapterItem>
              <ChapterItem>4-9 結論</ChapterItem>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            style={customPanelStyle}
            header="五、Excel 實作回測(2003-2019)"
          >
            <div>
              <ChapterItem>5-1 前言</ChapterItem>
              <ChapterItem>5-2 價格移動平均法 (Part 1) 原理</ChapterItem>
              <ChapterItem>5-2 價格移動平均法 (Part 2) 散布圖</ChapterItem>
              <ChapterItem>5-2 價格移動平均法 (Part 3) 演算法</ChapterItem>
              <ChapterItem>5-2 價格移動平均法 (Part 4) 結果</ChapterItem>
              <ChapterItem>5-3 成交量移動平均法</ChapterItem>
              <ChapterItem>5-4 價量移動平均法 (條件門檻法)</ChapterItem>
              <ChapterItem>5-5 價量移動平均法 (條件門檻法─多期間)</ChapterItem>
              <ChapterItem>
                5-6 價量移動平均法 (條件門檻法─多期間─移動波動)
              </ChapterItem>
              <ChapterItem>
                5-7 價量移動平均法 (加權評分法─移動波動)
              </ChapterItem>
              <ChapterItem>5-8 布林格帶法</ChapterItem>
              <ChapterItem>5-9 結論</ChapterItem>
            </div>
          </Collapse.Panel>
        </Collapse>
      </StyledSection>
    </>
  );
};

export default ProjectChapter;

import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

export const useProject = projectId => {
  const { loading, data, error } = useQuery(
    gql`
      query GET_PROJECT($projectId: String!) {
        fundraising_project_by_pk(id: $projectId) {
          title
          subtitle
          targetAmount
          discountTo
          plans(order_by: { position: asc }) {
            id
            title
            description
            salePrice
            listPrice
            onSale
            sales {
              amount
              sum
            }
          }
        }
      }
    `,
    { variables: { projectId } }
  );
  return {
    loading,
    error,
    project: loading || error ? null : data.fundraising_project_by_pk
  };
};

export const PROJECT_TIMELINE = [
  {
    title: "募資啟動",
    date: "2019.07.22（一）",
    content: {
      text: `募資網站啟動！`
    }
  },
  {
    title: "優惠開始",
    date: "2019.07.23（三）",
    content: {
      text: `感謝大家長期的支持，現在優惠價格ＸＸＸ，一起購買還可以XXXX`
    }
  }
  // {
  //   title: "Luft Cube＠4/24-4/28文博會展出｜來看樣品",
  //   date: "2019/06/08",
  //   content: {
  //     img: {
  //       src: "text2.png",
  //       alt: "測試",
  //       style: { width: "100%", paddingTop: "12px" }
  //     },
  //     text: `Luft Cube的贊助者您好，感謝大家支持，
  //       讓專案第二天就突破100萬！感謝各位呀！帶著的鼓勵，MIT的Luft
  //       Cube也前進松菸的文博會，開始面向世界，也在4/24獲得日本通路業者的好評喔。
  //       如果您想搶先看看，Luft Cube的樣品，歡迎來文博會喔！
  //       詳細資訊，請關注Luft.Qi粉絲專頁！Luft Cube團隊`
  //   }
  // }
];

import { Button } from "antd";
import React, { useContext } from "react";
import { TabContext } from "../tab/TabContext";

const SupportButton = () => {
  const { setActivePane } = useContext(TabContext);
  return (
    <Button
      type="primary"
      size="large"
      style={{ width: "100%" }}
      onClick={() => {
        setActivePane("support");
        const supportPageElem = document.getElementById("tabs");
        supportPageElem.scrollIntoView({
          behavior: "smooth"
        });
      }}
    >
      支持專案
    </Button>
  );
};

export default SupportButton;

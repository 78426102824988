import { Collapse, Icon, Typography } from "antd";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import Img01 from "../../images/content/01.jpg";
import Img02 from "../../images/content/02.jpg";
import Img03 from "../../images/content/03.jpg";
import Img04 from "../../images/content/04.jpg";
import Img05 from "../../images/content/05.jpg";
import Img06 from "../../images/content/06.jpg";
import Img07 from "../../images/content/07.jpg";
import Img08_1 from "../../images/content/08-1.jpg";
import Img08_2 from "../../images/content/08-2.jpg";
import Img08_3 from "../../images/content/08-3.jpg";
import Img09 from "../../images/content/09.jpg";
import Img10 from "../../images/content/10.jpg";
import Img11 from "../../images/content/11.jpg";
import Img12 from "../../images/content/12.jpg";
import Img13 from "../../images/content/13.jpg";
import Img14 from "../../images/content/14.jpg";
import { useProject } from "../../hooks";

const { Title } = Typography;

const StyledTitleDiv = styled.div`
  font-size: 20px;
  border-left: 6px solid #ff812c;
  padding: 6px 12px 6px 12px;
  margin-bottom: 32px;
`;

const StyledDiv = styled.div`
  color: #000;
  margin: 32px 0px;
`;

const StyledTextP = styled.p`
  line-height: 1.75;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
`;

const StyledTitle = ({ children }) => {
  return (
    <StyledTitleDiv>
      <Title level={4} style={{ marginBottom: 0 }}>
        {children}
      </Title>
    </StyledTitleDiv>
  );
};

const StyledText = ({ children }) => {
  return (
    <StyledDiv>
      <StyledTextP>{children}</StyledTextP>
    </StyledDiv>
  );
};

const customPanelStyle = {
  background: "#fff8f4",
  borderRadius: 4,
  marginBottom: 16,
  border: 0,
  overflow: "hidden"
};

const ProjectContext = () => {
  const { project } = useProject(process.env.REACT_APP_PROJECT_ID);
  return (
    <>
      <div className="mb-3">
        <img src={Img14} alt="Img14" style={{ width: "100%" }} />
      </div>
      <div className="mb-3">
        <img src={Img01} alt="Img01" style={{ width: "100%" }} />
        <img src={Img02} alt="Img02" style={{ width: "100%" }} />
      </div>
      <div>
        <Typography.Title level={4}>什麼是股票回測？</Typography.Title>
        <Typography.Paragraph>
          回測就是假設分析(What-If
          Analysis)，也就是透過股票交易歷史資料庫，我們可以對過去數十年的市場，測試各種投資策略的報酬率，以歸納出最佳的、最可靠的選股策略，是目前股票大師們最常用的股票分析方式。
        </Typography.Paragraph>
      </div>
      <div className="mb-3">
        <img src={Img05} alt="Img05" style={{ width: "100%" }} />
        <img src={Img06} alt="Img06" style={{ width: "100%" }} />
      </div>
      <div className="mb-3">
        <img src={Img03} alt="Img03" style={{ width: "100%" }} />
        <img src={Img04} alt="Img04" style={{ width: "100%" }} />
      </div>
      <div className="mb-3">
        <img src={Img07} alt="Img07" style={{ width: "100%" }} />
        <img src={Img08_1} alt="Img08_1" style={{ width: "100%" }} />
        <img src={Img08_2} alt="Img08_2" style={{ width: "100%" }} />
        <img src={Img08_3} alt="Img08_3" style={{ width: "100%" }} />
      </div>
      <div className="mb-3">
        <img src={Img09} alt="Img09" style={{ width: "100%" }} />
        <img src={Img10} alt="Img10" style={{ width: "100%" }} />
      </div>
      <div className="mb-3">
        <iframe
          title="press"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/KahFFJ5hm1Y"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="mb-3">
        <img src={Img11} alt="Img11" style={{ width: "100%" }} />
        <img src={Img12} alt="Img12" style={{ width: "100%" }} />
      </div>
      <div className="mb-3">
        <img src={Img13} alt="Img13" style={{ width: "100%" }} />
      </div>
      <div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <Icon type="caret-right" rotate={isActive ? 90 : 0} />
          )}
        >
          <Collapse.Panel
            header="Q1.上課前，需要準備什麼工具與先備知識呢？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              沒有任何條件限制，只要抱著一顆想「穩定投資獲利」的心就可以囉！
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q2.此堂課Excel有要求要哪個版本以上嗎？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              老師教學將使用「Microsoft Office 專業增強版
              2016」，學員可使用其他版本（新舊版、Mac版）進行操作與學習，但介面可能會有點稍加不同。
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q3.優惠時間到什麼時候？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              {project && project.discountTo
                ? `這堂課在 ${moment(project.discountTo).format(
                    "YYYY/MM/DD（dd）"
                  )}開放課程募資優惠`
                : `這堂課目前無優惠`}
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q4.這門課什麼時候開始上課？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              課程開通將於購買並“完成官網註冊24小時內(工作日）完成課程開通“，開通時會寄通知信通知。進入官網後，請於右上角點登入完成帳號註冊，且註冊信箱「務必」與下訂單填寫的信箱相同，才可進行資料核對與開通
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q5.課程有時間地點限制嗎？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              開課後，即可使用電腦、手機及平板登入上線上課，不用受到上課時間及地點的限制，皆可無限次數重複觀看課程內容。
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q6.可以問老師問題嗎？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              課程完成購買後，您隨即可加入「課程討論群」與老師和同學一同互動。
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q7.我要如何購買課程呢？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              非常的簡單！點擊「支持此專案」的字樣，就可以成為本堂線上課程的其中一位學員！
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel
            header="Q8.目前有哪些付款方式呢？"
            style={customPanelStyle}
          >
            <Typography.Paragraph>
              目前提供線上刷卡、ATM 轉帳及超商付款三種付款方式。
            </Typography.Paragraph>
          </Collapse.Panel>
          <Collapse.Panel header="Q9.我還有其他問題！" style={customPanelStyle}>
            <Typography.Paragraph>
              可以透過寄信到 service@mastertalks.tw 與我們聯繫！
            </Typography.Paragraph>
          </Collapse.Panel>
        </Collapse>
      </div>
    </>
  );
};

export default ProjectContext;

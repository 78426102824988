import React, { useState } from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton
} from "react-share";
import styled from "styled-components";
import ShareImage from "../../images/share.png";
const StyledShareIcon = styled.div`
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-position: center right;
  background-repeat: no-repeat;
  position: relative;
  background-image: url(${ShareImage});
`;

const Share = () => {
  const [modal2Visible, setModal2Visible] = useState(false);
  return (
    <div className="d-flex">
      <div className="mr-2">
        <FacebookShareButton url={window.location.origin}>
          <FacebookIcon size={24} />
        </FacebookShareButton>
      </div>
      <LineShareButton url={window.location.origin}>
        <LineIcon size={24} />
      </LineShareButton>
      {/* <StyledShareIcon onClick={() => setModal2Visible(true)} />
      <Modal
        centered
        footer={null}
        visible={modal2Visible}
        onCancel={() => setModal2Visible(false)}
      >
        <ShareContent />
      </Modal> */}
    </div>
  );
};

export default Share;

import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const StyledIntroContent = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
  position: relative;
`;

const IntroContent = ({ children }) => {
  return (
    <StyledIntroContent>
      <iframe
        title="intro"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/8DzW4wfl-aU"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{ position: "absolute", top: 0 }}
      />
    </StyledIntroContent>
  );
};

export default IntroContent;

import { Avatar, Comment } from "antd";
import React from "react";
import styled from "styled-components";

import Avatar1 from "../../images/people/avatar-01@3x.png";
import Avatar2 from "../../images/people/avatar-02@3x.png";
import Avatar3 from "../../images/people/avatar-03@3x.png";
import Avatar4 from "../../images/people/avatar-04@3x.png";
import Avatar5 from "../../images/people/avatar-05@3x.png";

const StyledTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 12px 0px;
`;

const comments = [
  {
    author: "蔡恒杰 Jeff Tsai",
    avatar: Avatar1,
    title: "葉教授的教學內容是真的有機會讓投資人在股票市場上獲利",
    paragraphs: [
      "市面上股票教學課程眾多,唯有葉教授老師的課程是有經過嚴謹的數理驗證且有真實獲利能力的股票教學課程",
      '身為一個金融交易員常常需要吸收大量的金融知識,但市場上大部分的股票教學往往是看圖說故事，也許在有舉例的股票可行，在沒舉例的股票完全無獲利能力，缺乏全面性的股票回測與嚴謹的計量驗證，而這才是所有投資人最需要的地方，在偶然的機會接觸到葉教授的課程，葉教授樸實且充滿學理與實證的內容著實讓人驚艷，最重要的是葉教授的教學內容是真的有機會讓投資人在股票市場上獲利，並隨著時間增長而達到財富自由的境界，如果您是一位願意靜下心來學習股票知識的投資人，衷心推薦葉教授的"科學化計量選股術"課程'
    ]
  },
  {
    author: "王淳恆",
    avatar: Avatar2,
    title: "用幾千元投資自己上這個課程，賺取一輩子的投資技能！",
    paragraphs: [
      "「人有旦夕禍福, 天有不測川普」，你還在看新聞投資股票嗎? 隨著川普的Twitter殺進殺出嗎? ",
      "G20中美貿易暫緩，第二天就買到漲停的大立光或大漲的台股，不久又跌回起漲點，幾次下來發現只是在賺賠之間來來回回瞎忙一場嗎?",
      "我從事價值型投資超過十年，自己建立了一套基本面財經AI分析的軟體。然而，2014年看到葉怡成教授的書籍讓我驚豔不已，原來除了用複雜的AI數學分析股票，還可以用更簡單且有效的公式來做價值分析。2017年葉怡成教授的「算股公式」光是封底就透露了很多量化方式做價值分析的成效和威力。",
      "MasterTalk推出葉怡成教授的課程更用深入簡出的方式講解股票投資的基本概念和價值分析方法，非常適合入門或在股海之中浮浮沉沉的投資者。如果你正在追求投資報酬率，那用幾千元投資自己上這個課程，賺取一輩子的投資技能，這個投資是再棒也不過了。"
    ]
  },
  {
    author: "王飛騰",
    avatar: Avatar3,
    title: "感謝葉老師可以提供這麼高水準的課程！",
    paragraphs: [
      "葉教授的課程太棒了，加上實際操作的部分，讓整個課程非常完美，真心推薦有投資台股的朋友購買，希望MasterTalk的產品都可以保持這樣的高水準，感謝葉老師的用心"
    ]
  },
  {
    author: "吳盛富",
    avatar: Avatar4,
    title: "葉教授的教學內容是真的有機會讓投資人在股票市場上獲利",
    paragraphs: [
      "我今天能對金融市場這樣的了解，甚至說是能有這樣的成就，都是我研究所的恩師葉怡成老師的教導，沒有葉老師沒有今天的我！",
      "無論是老師教導的基本面回測的系統，或是技術面的系統，都帶給我在研判金融市場上能夠熟悉歷史，並且對未來做出正確的推估！",
      "老師的技術面，更是掌握順勢系統與擺盪系統兩個最重要的準則，並且提供給我們非常精準的回測，讓我們能掌握各項技術指標的有效性！",
      "老師的課程，是非常精簡並且深入淺出，讓我們在極短的時間就可以學習到老師一生研究的精華！這一門課值得所有投資股票的人，一起來學習"
    ]
  },
  {
    author: "Wesley",
    avatar: Avatar5,
    title: "葉教授的教學內容是真的有機會讓投資人在股票市場上獲利",
    paragraphs: [
      "透過嚴謹的基本面計量回測，找到長期可以運作的投資模式，在任何時機點做出人類難以做出的理性決定，這是任何一位計量投資人必需花大量時間來琢磨的工作，葉老師透過最簡單的Excel工具讓所有人都可以輕鬆入門來做客製化回測，這對於想成為計量投資人的朋友可以節省很多力氣和少走冤枉路。"
    ]
  },
  {
    author: "吳尚洋",
    avatar: Avatar3,
    title: "葉教授的教學內容是真的有機會讓投資人在股票市場上獲利",
    paragraphs: ["講得很細微，所有面向都有提到，可以給初學者很多不同面向的參考"]
  },
  {
    author: "張晏彬",
    avatar: Avatar3,
    title: "葉教授的教學內容是真的有機會讓投資人在股票市場上獲利",
    paragraphs: [
      "不會撰寫Excel，上市個股根本無法全部比較，這堂課內容豐富，學習輕鬆易上手"
    ]
  }
];

const StudentSuggestionsContext = () => {
  return (
    <div className="pr-md-5">
      {comments.map((comment, idx) => (
        <Comment
          key={idx}
          author={comment.author}
          avatar={
            <Avatar size={44} src={comment.avatar} alt={comment.author} />
          }
          content={
            <>
              <StyledTitle>{comment.title}</StyledTitle>
              {comment.paragraphs.map(paragraph => (
                <p>{paragraph}</p>
              ))}
            </>
          }
        />
      ))}
    </div>
  );
};

export default StudentSuggestionsContext;

import { Progress } from "antd";
import React from "react";

const ProgressBar = ({ percent }) => {
  return (
    <div style={{ position: "relative" }}>
      <Progress
        type="circle"
        showInfo={false}
        percent={percent}
        width={76}
        strokeColor="#ff812c"
        strokeWidth={15}
      />
      <span
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)"
        }}
      >
        {percent}%
      </span>
    </div>
  );
};

export default ProgressBar;
